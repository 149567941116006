import {
  onMounted, ref, Ref, watch, useRoute,
} from '@nuxtjs/composition-api';

const useWindow = () => {
  const realWindowHeight: Ref<number> = ref(0);
  const isDesktop: Ref<boolean> = ref(true);
  const isTablet: Ref<boolean> = ref(false);
  const isMobile: Ref<boolean> = ref(false);
  const isDesktopOrTablet: Ref<boolean> = ref(true);
  const isMobileOrTablet: Ref<boolean> = ref(false);
  const realHeaderHeight: Ref<number> = ref(0);
  const blackFilters: Ref<boolean> = ref(false);

  const updateRealWindowHeight = () => {
    const headers = document.querySelectorAll('.sf-header-custom_container');
    if (headers.length > 0) {
      const header = headers[0] as HTMLElement | null;
      const headerHeight: number = header.offsetHeight;
      const headerFromTop: number = header.getBoundingClientRect().top;
      realWindowHeight.value = window.innerHeight - headerHeight - headerFromTop;
      realHeaderHeight.value = headerHeight + headerFromTop;
    }
  };

  const updateDeviceInfo = () => {
    isDesktop.value = window.innerWidth > 1023;
    isTablet.value = window.innerWidth > 767 && window.innerWidth < 1024;
    isMobile.value = window.innerWidth < 768;
    isDesktopOrTablet.value = isDesktop.value || isTablet.value;
    isMobileOrTablet.value = isMobile.value || isTablet.value;
    let header: HTMLElement;
    try {
      header = (document.querySelectorAll('.sf-header-custom_container')[0] as HTMLElement)
        || (document.querySelectorAll('.checkout-header')[0] as HTMLElement);
    } catch {
      console.warn('Could not find header height');
      return;
    }
    const headerHeight: number = header.offsetHeight;
    const headerFromTop: number = header.getBoundingClientRect().top;
    realWindowHeight.value = window.innerHeight - headerHeight - headerFromTop;
    realHeaderHeight.value = headerHeight + headerFromTop;
  };

  const handleBodyClass = (action: string, className: string) => {
    const element = document.body;
    if (action === 'addClass') {
      element.classList.add(className);
    } else {
      element.classList.remove(className);
    }
  };

  const handleHtmlClass = (action: string, className: string) => {
    const element = document.querySelector('html');
    if (action === 'addClass') {
      element.classList.add(className);
    } else {
      element.classList.remove(className);
    }
  };

  onMounted(() => {
    updateRealWindowHeight();
    window.addEventListener('scroll', () => {
      // large scrollY to "await" header's transition and avoid wrong updates during quick scrolls
      if (window.scrollY <= Number.parseInt('1000', 10)) {
        updateRealWindowHeight();
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      window.scrollY > (isDesktop.value ? 100 : 80) ? (blackFilters.value = true) : (blackFilters.value = false);
    });

    updateDeviceInfo();
    window.addEventListener('resize', () => {
      updateDeviceInfo();
    });
  });

  const route = useRoute();
  watch(
    () => route.value.fullPath,
    (newValue, oldValue) => {
      if (newValue !== oldValue) {
        handleBodyClass('removeClass', 'open_menu');
        handleHtmlClass('removeClass', 'open_menu_html');
        if (document.querySelector('body').style.pointerEvents === 'none') {
          document.querySelector('body').style.removeProperty('pointer-events');
        }
      }
    },
  );

  return {
    isMobile,
    isTablet,
    isDesktop,
    isMobileOrTablet,
    realWindowHeight,
    isDesktopOrTablet,
    updateRealWindowHeight,
    updateDeviceInfo,
    handleBodyClass,
    handleHtmlClass,
    realHeaderHeight,
    blackFilters,
  };
};

export default useWindow;
