import {
  useConfig,
  useCurrency,
  useStore,
  Currency,
  AvailableStores,
  StoreConfig,
} from '@vue-storefront/gemini';
import { computed, ComputedRef, useContext } from '@nuxtjs/composition-api';
import cookieNames from '~/enums/cookieNameEnum';

type UseGeminiConfiguration = () => {
  currencies: ComputedRef<Currency>;
  stores: ComputedRef<AvailableStores>;
  storeConfig: ComputedRef<StoreConfig>;
  selectedCurrency: ComputedRef<string | undefined>;
  selectedLocale: ComputedRef<string | undefined>;
  selectedStore: ComputedRef<string | undefined>;
  loadConfiguration: Promise<void>;
};
// @ts-ignore
export const useGeminiConfiguration: UseGeminiConfiguration = () => {
  const { app } = useContext();

  const {
    config: storeConfig,
    loadConfig,
  } = useConfig();

  const {
    stores,
    load: loadStores,
  } = useStore();

  const {
    currencies,
    load: loadCurrencies,
  } = useCurrency();

  const selectedCurrency = computed<string | undefined>(() => app.$cookies.get(cookieNames.currencyCookieName));

  const selectedLocale = computed<string | undefined>(() => app.$cookies.get(cookieNames.localeCookieName));

  const selectedStore = computed<string | undefined>(() => app.$cookies.get(cookieNames.storeCookieName));

  const loadConfiguration = () => {
    // eslint-disable-next-line promise/catch-or-return
    loadConfig().then(() => true);

    loadStores();
    loadCurrencies();
  };

  const cleanCookie = (async () => {
    const { i18n } = app;
    const safeStores = [];

    i18n.locales.forEach((locale) => {
      safeStores.push(locale.code);
    });

    if (!safeStores.includes(app.$cookies.get(cookieNames.localeCookieName))
      || !safeStores.includes(app.$cookies.get(cookieNames.storeCookieName))) {
      await app.$cookies.set(cookieNames.storeCookieName, app.$vsf.$gemini.config.locale);
      await app.$cookies.set(cookieNames.localeCookieName, app.$vsf.$gemini.config.locale);
    }

    if (app.$cookies.get(cookieNames.storeCookieName) !== app.$cookies.get(cookieNames.localeCookieName)) {
      await app.$cookies.set(cookieNames.storeCookieName, app.$vsf.$gemini.config.locale);
      await app.$cookies.set(cookieNames.localeCookieName, app.$vsf.$gemini.config.locale);
    }
  });

  return {
    currencies,
    stores,
    storeConfig,
    selectedCurrency,
    selectedLocale,
    selectedStore,
    loadConfiguration,
    cleanCookie,
  };
};
