import { useContext } from '@nuxtjs/composition-api';
// eslint-disable-next-line import/no-extraneous-dependencies
import { GetProductSearchParams } from '@vue-storefront/gemini-api';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Scalars } from '@vue-storefront/gemini-api/lib/types/GraphQL';

type UseContextReturn = ReturnType<typeof useContext>;
type ProductSearchParams = (GetProductSearchParams & { configurations?: Scalars['ID'] });

export const getProductDetailsCommand = {
  execute: async (context: UseContextReturn, searchParams: ProductSearchParams) => {
    const { data } = await context.app.$vsf.$gemini.api.productDetail(searchParams);

    return data?.productDetail ?? null;
  },
};
