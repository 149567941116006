import { useContext } from '@nuxtjs/composition-api';
import imageSizes from '~/enums/imageEnums';

const useImage = () => {
  // @ts-ignore
  const { $vsf } = useContext();
  /**
   * Extract image path from Gemini URL.
   *
   * @param fullImageUrl {string}
   *
   * @return {string}
   */
  const getGeminiImage = (fullImageUrl: string) => {
    const { imageProvider, geminiBaseUrl } = $vsf.$gemini.config;

    if (imageProvider !== 'ipx') {
      const url = fullImageUrl.split(`${geminiBaseUrl}`);

      const regex = /cache\/(.*?)\//gm;
      return url[1].replace(regex, '');
    }

    return fullImageUrl;
  };

  return {
    getGeminiImage,
    imageSizes,
  };
};

export default useImage;
