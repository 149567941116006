/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/unbound-method */
import { computed, useContext } from '@nuxtjs/composition-api';
import {
  KlaviyoAddToCartEventData, KlaviyoCheckoutEventData, KlaviyoProduct, ExtendedProduct as Product,
} from '~/types/klaviyo';
import {
  CartItem, useCart, useUser,
} from '@vue-storefront/gemini';

const useKlaviyo = () => {
  const klaviyo = computed(() => typeof window !== 'undefined' && window._learnq);
  const { cart, load: loadCart } = useCart();
  const { user, load: loadUser } = useUser();
  const {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    $vsf: {
      $gemini: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        config: { storeUrlSecure, klaviyoCompanyId, klaviyoListId },
      },
    },
    route: {
      value: { path },
    },
    app: {
      i18n: {
        localeProperties: { defaultCurrency: currentCurrency },
      },
    },
  } = useContext();

  const customLogger = (message: string, level?: string) => {
    const validLevels = ['log', 'debug', 'info', 'warn', 'error'];
    if (level && validLevels.includes(level)) {
      console[level](`%c[Klaviyo][${level}]:`, 'color: #fcc965; background-color: #232426', message);
    } else {
      console.debug('%c[Klaviyo][debug]:', 'color: #fcc965; background-color: #232426', message);
    }
  };

  const cartIsValidForKlaviyo = async (): Promise<boolean> => {
    if (!cart.value) {
      await loadUser();
      await loadCart();
    }
    return !!(cart.value && cart.value.email);
  };

  const isIdentified = (): boolean => !!klaviyo?.value?.isIdentified();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const currentLocale = computed<string>(() => (path?.split('/')?.[1] === 'it' ? 'it' : 'en'));

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  const identifiedEmail = computed<string | undefined>(() => (cart.value && cart.value.email) || (user.value && user.value.email));

  const cartItemToKlaviyoProduct = (item: CartItem): KlaviyoProduct => ({
    ProductID: item.product.sku,
    SKU: item.product.merchant_sku,
    ProductName: item.product.name,
    Quantity: item.quantity,
    ItemPrice: item.prices.price.value,
    RowTotal: item.prices.row_total.value,
    ProductURL: `${storeUrlSecure}/${currentLocale.value}/${item.product?.url_key || ''}`,
    ImageURL: item.product.thumbnail.url,
    ProductCategories: item.product.categories.map((category) => category.name),
    Currency: currentCurrency,
  });

  const productToKlaviyoProduct = (product: Product): KlaviyoProduct => ({
    ProductID: product.sku,
    SKU: product.merchant_sku,
    ProductName: product.name,
    Quantity: 1,
    ItemPrice: product.price_range.minimum_price.final_price.value,
    ItemBasePrice: product.price_range.minimum_price.regular_price.value,
    RowTotal: product.price_range.minimum_price.final_price.value,
    ProductURL: `${storeUrlSecure}/${currentLocale.value}/${product.url_key || ''}`,
    ImageURL: product.image.url,
    ProductCategories: product.categories.map((category) => category.name),
    ProductSize: product?.configurable_product_options_selection?.variant?.size_attribute?.label || '',
    ProductColor: product?.configurable_product_options_selection?.variant?.color_attribute?.label || '',
  });

  const cartItemsToKlaviyoCheckoutEventProducts = (): KlaviyoProduct[] => cart.value.items.map((item) => cartItemToKlaviyoProduct(item));

  const generateCheckoutEventId = () => {
    const date = new Date();
    const timestamp = date.getTime();
    const cartId: string = cart.value?.id?.split('::')[1] || '';
    return `${cartId}_${timestamp}`;
  };

  const identifyKlaviyo = (email?: string) => {
    if (!identifiedEmail.value && !email) return;
    try {
      klaviyo.value.push(['identify', { $email: email || identifiedEmail.value }]);
      customLogger(`Identify -> ${email || identifiedEmail.value}`);
    } catch (error) {
      customLogger(error, 'error');
    }
  };

  const pushToKlaviyo = async (eventName: string, eventData: object): Promise<boolean> => {
    if (!klaviyo.value) {
      customLogger('Klaviyo not initialized', 'warn');
      return false;
    }
    if (!isIdentified()) {
      try {
        await loadUser();
        await loadCart();
        identifyKlaviyo();
      } catch (error) {
        customLogger(error, 'error');
      }
    }
    if (!isIdentified()) return false;
    try {
      const r = klaviyo.value.push(['track', eventName, eventData]);
      return !!r;
    } catch (error) {
      customLogger(error, 'error');
    }
    return false;
  };

  const klaviyoCheckoutPush = async (eventName?: string): Promise<void> => {
    const canPush = await cartIsValidForKlaviyo();
    if (!canPush) return;
    const eventId = generateCheckoutEventId();
    try {
      await pushToKlaviyo(eventName || 'Started Checkout - Account', {
        $event_id: eventId,
        $value: cart.value.prices.grand_total.value,
        ItemNames: cart.value.items.map((item: CartItem) => item.product.name),
        ItemImages: cart.value.items.map((item: CartItem) => item.product.thumbnail.url),
        ItemUrls: cart.value.items.map((item: CartItem) => `${storeUrlSecure}/${currentLocale.value}/${item.product.url_key || ''}`),
        ItemsFinalPrices: cart.value.items.map((item: CartItem) => item.prices.price.value),
        // @ts-ignore
        CheckoutURL: eventName ? `${storeUrlSecure}${path}` : `${storeUrlSecure}/${currentLocale.value}/checkout'`,
        Categories: [...new Set(cart.value.items.flatMap((item: CartItem) => item.product.categories.map((category) => category.name)))],
        Locale: currentLocale.value,
        Currency: currentCurrency,
        Items: cartItemsToKlaviyoCheckoutEventProducts(),
      } as KlaviyoCheckoutEventData);
      customLogger(`Checkout -> ${eventName || 'Started Checkout - Account'}`);
    } catch (error) {
      customLogger(error, 'error');
    }
  };

  type UserAccount = {
    firstname: string;
    lastname: string;
  };

  type ShippingData = {
    street: string;
    apartment: string;
    city: string;
    region: string;
    country_code: string;
    postcode: string;
    firstname: string;
    lastname: string;
  };

  const klaviyoOrderPlacedPush = async (
    orderId: string,
    orderTotal: string,
    orderItems: CartItem[],
    userAccount: UserAccount,
    shippingData: ShippingData,
  ) => {
    const canPush = await cartIsValidForKlaviyo();
    if (!canPush) return;
    const eventId = generateCheckoutEventId();
    try {
      await pushToKlaviyo('Order Placed', {
        $event_id: eventId,
        currency: 'EUR',
        customer_firstname: userAccount?.firstname,
        customer_lastname: userAccount?.lastname,
        item_names: orderItems.map((item: CartItem) => item.product.name),
        item_qtys: orderItems.map((item: CartItem) => item.quantity),
        item_skus: orderItems.map((item: CartItem) => item.product.merchant_sku),
        locale: currentLocale.value,
        market: 'World',
        order_number: orderId,
        order_total: orderTotal,
        shipping_address_address_street: `${shippingData?.street} ${shippingData?.apartment}`,
        shipping_address_address_city: shippingData?.city,
        shipping_address_address_region: shippingData?.region,
        shipping_address_address_country: shippingData?.country_code,
        shipping_address_address_postal_code: shippingData?.postcode,
        shipping_address_recipient_firstname: shippingData?.firstname,
        shipping_address_recipient_lastname: shippingData?.lastname,
      });
      customLogger(`OrderPlaced -> ${orderId}`);
    } catch (error) {
      customLogger(error, 'error');
    }
  };

  const klaviyoAddToCartPush = async (product: Product, quantity: number) => {
    const canPush = await cartIsValidForKlaviyo();
    if (!canPush) return;
    const klaviyoProduct = productToKlaviyoProduct(product);
    try {
      await pushToKlaviyo('Added to Cart', {
        AddedItemValue: klaviyoProduct.ItemPrice,
        AddedItemProductName: klaviyoProduct.ProductName,
        AddedItemProductID: klaviyoProduct.ProductID,
        AddedItemSKU: klaviyoProduct.SKU,
        AddedItemCategories: klaviyoProduct.ProductCategories,
        AddedItemImageURL: klaviyoProduct.ImageURL,
        AddedItemURL: klaviyoProduct.ProductURL,
        AddedItemPrice: klaviyoProduct.ItemPrice,
        AddedItemQuantity: quantity,
        AddedItemSize: klaviyoProduct.ProductSize,
        AddedItemColor: klaviyoProduct.ProductColor,
        CheckoutURL: `${storeUrlSecure}/${currentLocale.value}/checkout`,
        Locale: currentLocale.value,
        Currency: currentCurrency,
      } as KlaviyoAddToCartEventData);
      customLogger(`AddToCartPush -> sku: ${klaviyoProduct.SKU} - qty: ${quantity}`);
    } catch (error) {
      customLogger(error, 'error');
    }
  };

  const klaviyoApplyCouponPush = async (couponCode: string) => {
    const canPush = await cartIsValidForKlaviyo();
    if (!canPush) return;
    const eventId = generateCheckoutEventId();
    try {
      await pushToKlaviyo('Added Coupon', {
        $event_id: eventId,
        CouponCode: couponCode,
      });
      customLogger(`ApplyCouponPush -> ${couponCode}`);
    } catch (error) {
      customLogger(error, 'error');
    }
  };

  const klaviyoPagePush = async (pageName: string) => {
    try {
      await pushToKlaviyo('Viewed Page', {
        PageName: pageName,
      });
      customLogger(`PagePush -> ${pageName}`);
    } catch (error) {
      customLogger(error, 'error');
    }
  };

  const klaviyoViewedProductPush = async (product: Product) => {
    try {
      const klaviyoProduct = productToKlaviyoProduct(product);
      await pushToKlaviyo('Viewed Product', {
        ProductName: klaviyoProduct.ProductName,
        ProductID: klaviyoProduct.ProductID,
        SKU: klaviyoProduct.SKU,
        ProductSize: klaviyoProduct.ProductSize,
        ProductColor: klaviyoProduct.ProductColor,
        ProductImageURL: klaviyoProduct.ImageURL,
        ProductCategories: klaviyoProduct.ProductCategories,
        ProductFinalPrice: klaviyoProduct.ItemPrice,
        ProductRegularPrice: klaviyoProduct.ItemBasePrice,
        ProductURL: klaviyoProduct.ProductURL,
        Locale: currentLocale.value,
        Currency: currentCurrency,
      });
      customLogger(`ViewedProductPush -> ${product.merchant_sku}`);
    } catch (error) {
      customLogger(error, 'error');
    }
  };

  const klaviyoCreateClientSubscription = async (
    subscriberEmail: string,
    firstName: string,
    lastName: string,
    isCustomer: boolean,
    customSource: string,
  ) => {
    const url = `https://a.klaviyo.com/client/subscriptions?company_id=${klaviyoCompanyId}`;
    const headers = {
      'Content-Type': 'application/vnd.api+json',
      revision: '2024-10-15',
    };
    const data = {
      data: {
        type: 'subscription',
        attributes: {
          profile: {
            data: {
              type: 'profile',
              attributes: {
                first_name: firstName || '',
                last_name: lastName || '',
                email: subscriberEmail,
                properties: {
                  market: 'World',
                  custom_source: customSource,
                  preferred_locale: currentLocale.value,
                  is_customer: isCustomer,
                },
              },
            },
          },
        },
        relationships: {
          list: {
            data: {
              type: 'list',
              id: klaviyoListId,
            },
          },
        },
      },
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Couldn\'t create subscription');
      }
    } catch (error) {
      console.error('Error creating subscription:', error);
    }
  };

  return {
    klaviyoCheckoutPush,
    klaviyoOrderPlacedPush,
    klaviyoAddToCartPush,
    klaviyoApplyCouponPush,
    identifyKlaviyo,
    klaviyoPagePush,
    klaviyoViewedProductPush,
    klaviyoCreateClientSubscription,
  };
};

export default useKlaviyo;
