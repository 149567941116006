// @ts-ignore
import { productGetters } from '@vue-storefront/gemini';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Category, Product } from '@vue-storefront/gemini-api';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getSeoCategorySlug(categories: Category[]): String {
  if (categories.length === 1) {
    return categories[0].url_path;
  }

  const seoCategories = categories.filter((category) => category.is_anchor);
  if (seoCategories.length === 0) {
    return categories[0].url_path;
  }

  return seoCategories[0].url_path;
}

const useUrlHelpers = () => {
  const buildProductUrl = (product: Product): String => {
    const productSlug = productGetters.getSlug(product);
    return `${productSlug}`;
  };

  return {
    buildProductUrl,
  };
};

export default useUrlHelpers;
