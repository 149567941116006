import {
  useUrlResolver as urlResolver,
} from '@vue-storefront/gemini';
import { useRoute } from '@nuxtjs/composition-api';

export const useUrlResolver = () => {
  const route = useRoute();
  const { params: { path } } = route.value;

  const {
    search,
    result,
    loading,
    error,
  } = urlResolver(`router:${path}`);

  return {
    path,
    search: async () => {
      await search({ url: path });
    },
    result,
    loading,
    error,
  };
};
